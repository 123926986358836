import * as React from 'react';
import { Route, Router, Switch } from 'react-router';
import { createBrowserHistory } from 'history';
import { AuthToken } from "./components/Service/Config/AuthToken";
import { BoxMsg, Sweetalert } from "./components/Systems/SystemComponent";
import Countdown from "react-countdown";
import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content';
import './custom.css';

import LayoutMain from './components/_Layout/LayoutMain';
import MP_Back from './components/_Layout-Admin/MP_Back';

import Intro from './components/main-intro/intro';
import Home from './components/main-home/home';
import Login from './components/login/Login';
import Admin_User_Permission_List from './components/admin-user-permission/Admin_User_Permission_List';
import Admin_Userpermission_EditGroup from './components/admin-user-permission/Admin_Userpermission_EditGroup';
import Admin_Userpermission_EditInfo from './components/admin-user-permission/Admin_Userpermission_EditInfo';
import Admin_News_Edit from './components/admin-news/Admin_News_Edit';
import Admin_News_List from './components/admin-news/Admin_News_List';
import Admin_Banner_Edit from './components/admin-banner/Admin_Banner_Edit';
import Admin_Banner_List from './components/admin-banner/Admin_Banner_List';
import Admin_IntroPage_List from './components/admin-intro-page/admin-intropage-list';
import Admin_IntroPage_Edit from './components/admin-intro-page/admin-intropage-edit';
import Admin_Institution_Edit from './components/admin-institution/Admin_Institution_Edit';
import Admin_Institution_List from './components/admin-institution/Admin_Institution_List';
import Admin_Map_Edit from './components/admin-map/Admin_Map_Edit';
import Admin_Map_List from './components/admin-map/Admin_Map_List';
import Admin_Contact_Us_Edit from './components/admin-contact-us/Admin_Contact_Us_Edit';
import Admin_Contact_Us_List from './components/admin-contact-us/Admin_Contact_Us_List';
import Admin_Contact_Us_EditGroup from './components/admin-contact-us/Admin_Contact_Us_EditGroup';
import admin_news_edit from './components/admin-news/admin-news-edit';
import admin_news_list from './components/admin-news/admin-news-list';
import admin_forest_story_edit from './components/admin-forest-story/admin-forest-story-edit';
import admin_forest_story_list from './components/admin-forest-story/admin-forest-story-list';
import Admin_Social_List from './components/admin-social/Admin_Social_List';
import Admin_Social_Edit from './components/admin-social/Admin_Social_Edit';
import Main_Map from './components/main-map/Main_Map';
import Main_Contact_Us from './components/main-contact-us/Main_Contact_Us';
import admin_learning_center_edit from './components/admin-learning-center/admin-learning-center-edit';
import admin_learning_center_list from './components/admin-learning-center/admin-learning-center-list';
import admin_lt_project_edit from './components/admin-lt-project/admin-lt-project-edit';
import admin_lt_project_list from './components/admin-lt-project/admin-lt-project-list';

import admin_hl_project_edit from './components/admin-hl-project/admin-hl-project-edit';
import admin_hl_project_list from './components/admin-hl-project/admin-hl-project-list';
import admin_v_project_edit from './components/admin-v-project/admin-v-project-edit';
import admin_v_project_list from './components/admin-v-project/admin-v-project-list';
import admin_plants_animals_edit from './components/admin-knowledge/plants-animals/plants-animals-edit';
import admin_plants_animals_list from './components/admin-knowledge/plants-animals/plants-animals-list';
import admin_videos_edit from './components/admin-knowledge/videos/videos-edit';
import admin_videos_list from './components/admin-knowledge/videos/videos-list';
import admin_ebooks_edit from './components/admin-knowledge/ebooks/ebooks-edit';
import admin_ebooks_list from './components/admin-knowledge/ebooks/ebooks-list';
import admin_research_edit from './components/admin-knowledge/research/research-edit';
import admin_research_list from './components/admin-knowledge/research/research-list';
import admin_law_edit from './components/admin-knowledge/law/law-edit';
import admin_law_list from './components/admin-knowledge/law/law-list';

import admin_event_edit from './components/admin-event/Admin_Event_Edit';
import admin_event_list from './components/admin-event/Admin_Event_List';

import Admin_CMS from './components/admin-cms/admin-cms';
import Admin_CMS_List from './components/admin-cms/admin-cms-list';

import admin_learning_center from './components/admin-learning-center/admin-learning-center';
import admin_learning_center_type_edit from './components/admin-learning-center/admin-learning-center-type-edit';

import Admin_hl_Project from './components/admin-hl-project/admin-hl-project';
import Admin_hl_project_sub_edit from './components/admin-hl-project/admin-hl-project-sub-edit';
import Admin_hl_Project_sub_list from './components/admin-hl-project/admin-hl-project-sub-list';
import Admin_hl_project_sub_edit2 from './components/admin-hl-project/admin-hl-project-sub-edit2';
import Admin_hl_Project_sub_list2 from './components/admin-hl-project/admin-hl-project-list';
import admin_hl_project_edit2 from './components/admin-hl-project/admin-hl-project-edit';

import Admin_knowledge from './components/admin-knowledge/admin-knowledge';
import Admin_knowledge_sub_edit from './components/admin-knowledge/admin-knowledge-sub-edit';
import Admin_knowledge_sub_list from './components/admin-knowledge/admin-knowledge-sub-list';
import Admin_knowledge_sub_edit2 from './components/admin-knowledge/admin-knowledge-sub-edit2';
import Admin_knowledge_sub_list2 from './components/admin-knowledge/admin-knowledge-list';
import Admin_knowledge_edit from './components/admin-knowledge/admin-knowledge-edit';

import IndexPage from './components/main-home/index';
import Admin_InfoGraphics from './components/admin-infographics/admin-infographics';
import Admin_BackGround from './components/admin-background/admin-background';
import TestConnection from './components/main-home/testconnection';

import News_Content_List from './components/main-news/news-list';
import News_Content_Detail from './components/main-news/news-detail';
import Institute from './components/main-institute/institute';
import Forest_Story_List from './components/main-forest-story/forest-story-list';
import Forest_Story_Detail from './components/main-forest-story/forest-story-detail';
import Learning_Center_Index from './components/main-learning-center/learning-center-index';
import Learning_Center_List from './components/main-learning-center/learning-center-sub';
import Learning_Center_Detail from './components/main-learning-center/learning-center-detail';

import HL_Project_Index from './components/main-hl-project/hl-project-index';
import HL_Project_Sub from './components/main-hl-project/hl-project-index2';
import HL_Project_List from './components/main-hl-project/hl-project-list';
import HL_Project_Detail from './components/main-hl-project/hl-project-detail';

import Knowledge_Index from './components/main-knowledge/knowledge-index';
import Knowledge_Sub from './components/main-knowledge/knowledge-index2';
import Knowledge_List from './components/main-knowledge/knowledge-list';
import Knowledge_Detail from './components/main-knowledge/knowledge-detail';
import Knowledge_Detail_Plant from './components/main-knowledge/knowledge-datail-plant';
import Knowledge_Detail_Animal from './components/main-knowledge/knowledge-datail-animal';
import Main_Ebook from './components/main-content/content-ebook';

import Event_Detail from './components/main-event/event-detail';
import Main_Search from './components/main-search/search';

import Report_Manage_Information from './components/report/Report_Manage_Information';
import Report_Forest_Map_Data from './components/report/Report_Forest_Map_Data';
import Report_Like_And_Share from './components/report/Report_Like_And_Share';
import Report_Traffic_Statistics from './components/report/Report_Traffic_Statistics';

import Admin_Subscribe_Edit from './components/admin-subscribe/Admin_Subscribe_Edit';
import Admin_Subscribe_List from './components/admin-subscribe/Admin_Subscribe_List';

import Cookies_Policy from './components/cookies-policy/Cookies_Policy';

const Swal2 = withReactContent(Swal);

const baseUrl = document
    .getElementsByTagName("base")[0]
    .getAttribute("href") as string;
const history = createBrowserHistory({ basename: baseUrl });

export function getHistory() {
    return history;
}

const route = [
    { path: "/", component: IndexPage, exact: true },
    { path: "/index", component: Intro, exact: true },
    { path: "/TestConnection", component: TestConnection, exact: true },




    { path: "/home", component: Home, layout: LayoutMain, exact: true, layoutProps: { MenuID_Selected: 1, sTitle: "Home" } },
    { path: "/institute", component: Institute, layout: LayoutMain, exact: true, layoutProps: { MenuID_Selected: 2, sTitle: "" } },
    { path: "/news", component: News_Content_List, layout: LayoutMain, exact: true, layoutProps: { MenuID_Selected: 3, sTitle: "" } },
    { path: "/news-detail", component: News_Content_Detail, layout: LayoutMain, exact: true, layoutProps: { MenuID_Selected: 3, sTitle: "" } },
    { path: "/forest-story", component: Forest_Story_List, layout: LayoutMain, exact: true, layoutProps: { MenuID_Selected: 4, sTitle: "" } },
    { path: "/forest-story-detail", component: Forest_Story_Detail, layout: LayoutMain, exact: true, layoutProps: { MenuID_Selected: 4, sTitle: "" } },
    
    { path: "/learning-center", component: Learning_Center_Index, layout: LayoutMain, exact: true, layoutProps: { MenuID_Selected: 5, sTitle: "" } },
    { path: "/learning-center-list", component: Learning_Center_List, layout: LayoutMain, exact: true, layoutProps: { MenuID_Selected: 5, sTitle: "" } },
    { path: "/learning-center-detail", component: Learning_Center_Detail, layout: LayoutMain, exact: true, layoutProps: { MenuID_Selected: 5, sTitle: "" } },
    
    { path: "/hl-project", component: HL_Project_Index, layout: LayoutMain, exact: true, layoutProps: { MenuID_Selected: 6, sTitle: "" } },
    //{ path: "/hl-project-sub", component: HL_Project_Sub, layout: LayoutMain, exact: true, layoutProps: { MenuID_Selected: 6, sTitle: "" } },
    { path: "/hl-project-list", component: HL_Project_List, layout: LayoutMain, exact: true, layoutProps: { MenuID_Selected: 6, sTitle: "" } },
    { path: "/hl-project-detail", component: HL_Project_Detail, layout: LayoutMain, exact: true, layoutProps: { MenuID_Selected: 6, sTitle: "" } },
    
    { path: "/knowledge", component: Knowledge_Index, layout: LayoutMain, exact: true, layoutProps: { MenuID_Selected: 7, sTitle: "" } },
    //{ path: "/knowledge-sub", component: Knowledge_Sub, layout: LayoutMain, exact: true, layoutProps: { MenuID_Selected: 7, sTitle: "" } },
    { path: "/knowledge-list", component: Knowledge_List, layout: LayoutMain, exact: true, layoutProps: { MenuID_Selected: 7, sTitle: "" } },
    { path: "/knowledge-detail", component: Knowledge_Detail, layout: LayoutMain, exact: true, layoutProps: { MenuID_Selected: 7, sTitle: "" } },
    { path: "/knowledge-detail-plant", component: Knowledge_Detail_Plant, layout: LayoutMain, exact: true, layoutProps: { MenuID_Selected: 7, sTitle: "" } },
    { path: "/knowledge-detail-animal", component: Knowledge_Detail_Animal, layout: LayoutMain, exact: true, layoutProps: { MenuID_Selected: 7, sTitle: "" } },
    { path: "/content-ebook", component: Main_Ebook, exact: true, layoutProps: { MenuID_Selected: 99, sTitle: "" } },

    { path: "/search", component: Main_Search, layout: LayoutMain, exact: true, layoutProps: { MenuID_Selected: 99, sTitle: "" } },

    { path: "/event-detail", component: Event_Detail, layout: LayoutMain, exact: true, layoutProps: { MenuID_Selected: 1, sTitle: "" } },



    { path: "/main-map", component: Main_Map, layout: LayoutMain, exact: true, layoutProps: { MenuID_Selected: 8, sTitle: "" } },
    { path: "/cookies-policy", component: Cookies_Policy, layout: LayoutMain, exact: true },

    { path: "/main-contact-us", component: Main_Contact_Us, layout: LayoutMain, exact: true, layoutProps: { MenuID_Selected: 99, sTitle: "" } },


    { path: "/login", component: Login, exact: true },

    { path: "/admin-intropage-list", component: Admin_IntroPage_List, layout: MP_Back, exact: true, layoutProps: { MenuID_Selected: 1, sTitle: "หน้าเริ่มต้น" } },
    { path: "/admin-intropage-edit", component: Admin_IntroPage_Edit, layout: MP_Back, exact: true, layoutProps: { MenuID_Selected: 1, sTitle: "หน้าเริ่มต้น" } },

    { path: "/admin-institution-edit", component: Admin_Institution_Edit, layout: MP_Back, exact: true, layoutProps: { MenuID_Selected: 3, sTitle: "Edit" } },
    { path: "/admin-institution-list", component: Admin_Institution_List, layout: MP_Back, exact: true, layoutProps: { MenuID_Selected: 3, sTitle: "สถาบัน" } },

    { path: "/admin-news-edit", component: admin_news_edit, layout: MP_Back, exact: true, layoutProps: { MenuID_Selected: 4, sTitle: "Edit" } },
    { path: "/admin-news-list", component: admin_news_list, layout: MP_Back, exact: true, layoutProps: { MenuID_Selected: 4, sTitle: "ข่าวสาร" } },

    { path: "/admin-forest-story-edit", component: admin_forest_story_edit, layout: MP_Back, exact: true, layoutProps: { MenuID_Selected: 5, sTitle: "Edit" } },
    { path: "/admin-forest-story-list", component: admin_forest_story_list, layout: MP_Back, exact: true, layoutProps: { MenuID_Selected: 5, sTitle: "เรื่องเล่าจากป่า" } },

    { path: "/admin-learning-center-list", component: admin_learning_center, layout: MP_Back, exact: true, layoutProps: { MenuID_Selected: 6, sTitle: "ศูนย์ฯเรียนรู้" } },
    { path: "/admin-learning-center-type-edit", component: admin_learning_center_type_edit, layout: MP_Back, exact: true, layoutProps: { MenuID_Selected: 6, sTitle: "ศูนย์ฯเรียนรู้" } },
    { path: "/admin-learning-center-sub-list", component: admin_learning_center_list, layout: MP_Back, exact: true, layoutProps: { MenuID_Selected: 6, sTitle: "ศูนย์ฯเรียนรู้" } },
    { path: "/admin-learning-center-edit", component: admin_learning_center_edit, layout: MP_Back, exact: true, layoutProps: { MenuID_Selected: 6, sTitle: "ศูนย์ฯเรียนรู้" } },

    { path: "/admin-map-edit", component: Admin_Map_Edit, layout: MP_Back, exact: true, layoutProps: { MenuID_Selected: 9, sTitle: "Edit" } },
    { path: "/admin-map-list", component: Admin_Map_List, layout: MP_Back, exact: true, layoutProps: { MenuID_Selected: 9, sTitle: "แผนที่ป่า ปตท." } },

    { path: "/admin-contact-us-edit", component: Admin_Contact_Us_Edit, layout: MP_Back, exact: true, layoutProps: { MenuID_Selected: 10, sTitle: "Edit" } },
    { path: "/admin-contact-us-list", component: Admin_Contact_Us_List, layout: MP_Back, exact: true, layoutProps: { MenuID_Selected: 10, sTitle: "ติดต่อสอบถาม" } },

    { path: "/admin-contact-us-editgroup", component: Admin_Contact_Us_EditGroup, layout: MP_Back, exact: true, layoutProps: { MenuID_Selected: 10, sTitle: "Edit" } },
    { path: "/admin-user-permission-list", component: Admin_User_Permission_List, layout: MP_Back, exact: true, layoutProps: { MenuID_Selected: 11, sTitle: "สิทธิผู้ดูแลระบบ" } },

    { path: "/admin-user-permission-editgroup", component: Admin_Userpermission_EditGroup, layout: MP_Back, exact: true, layoutProps: { MenuID_Selected: 11, sTitle: "User Permission EditGroup" } },
    { path: "/admin-user-permission-editinfo", component: Admin_Userpermission_EditInfo, layout: MP_Back, exact: true, layoutProps: { MenuID_Selected: 11, sTitle: "User Permission EditInfo" } },

    { path: "/admin-cms-list", component: Admin_CMS_List, layout: MP_Back, exact: true, layoutProps: { MenuID_Selected: 13, sTitle: "CMS" } },
    { path: "/admin-cms", component: Admin_CMS, layout: MP_Back, exact: true, layoutProps: { MenuID_Selected: 13, sTitle: "CMS" } },

    { path: "/admin-banner-edit", component: Admin_Banner_Edit, layout: MP_Back, exact: true, layoutProps: { MenuID_Selected: 14, sTitle: "Edit" } },
    { path: "/admin-banner-list", component: Admin_Banner_List, layout: MP_Back, exact: true, layoutProps: { MenuID_Selected: 14, sTitle: "slide ภาพ" } },

    { path: "/admin-infographics", component: Admin_InfoGraphics, layout: MP_Back, exact: true, layoutProps: { MenuID_Selected: 15, sTitle: "ภาพสู่ความสำเร็จ" } },
    { path: "/admin-background", component: Admin_BackGround, layout: MP_Back, exact: true, layoutProps: { MenuID_Selected: 16, sTitle: "ภาพพื้นหลัง" } },

    { path: "/admin-social-list", component: Admin_Social_List, layout: MP_Back, exact: true, layoutProps: { MenuID_Selected: 17, sTitle: "ช่องทางการติดต่อ" } },
    { path: "/admin-social-edit", component: Admin_Social_Edit, layout: MP_Back, exact: true, layoutProps: { MenuID_Selected: 17, sTitle: "Edit" } },

    { path: "/admin-hl-project-list", component: Admin_hl_Project, layout: MP_Back, exact: true, layoutProps: { MenuID_Selected: 7, sTitle: "โครงการสำคัญ" } },
    { path: "/admin-hl-project-type-edit", component: Admin_hl_project_sub_edit, layout: MP_Back, exact: true, layoutProps: { MenuID_Selected: 7, sTitle: "โครงการสำคัญ" } },
    // { path: "/admin-hl-project-sub-list", component: Admin_hl_Project_sub_list, layout: MP_Back, exact: true, layoutProps: { MenuID_Selected: 7, sTitle: "โครงการสำคัญ" } },
    // { path: "/admin-hl-project-sub-edit2", component: Admin_hl_project_sub_edit2, layout: MP_Back, exact: true, layoutProps: { MenuID_Selected: 7, sTitle: "โครงการสำคัญ" } },
    { path: "/admin-hl-project-sub-list", component: Admin_hl_Project_sub_list2, layout: MP_Back, exact: true, layoutProps: { MenuID_Selected: 7, sTitle: "โครงการสำคัญ" } },
    { path: "/admin-hl-project-edit", component: admin_hl_project_edit2, layout: MP_Back, exact: true, layoutProps: { MenuID_Selected: 7, sTitle: "โครงการสำคัญ" } },

    { path: "/admin-knowledge-list", component: Admin_knowledge, layout: MP_Back, exact: true, layoutProps: { MenuID_Selected: 8, sTitle: "องค์ความรู้" } },
    { path: "/admin-knowledge-type-edit", component: Admin_knowledge_sub_edit, layout: MP_Back, exact: true, layoutProps: { MenuID_Selected: 8, sTitle: "องค์ความรู้" } },
    // { path: "/admin-knowledge-sub-list", component: Admin_knowledge_sub_list, layout: MP_Back, exact: true, layoutProps: { MenuID_Selected: 8, sTitle: "องค์ความรู้" } },
    // { path: "/admin-knowledge-sub-edit2", component: Admin_knowledge_sub_edit2, layout: MP_Back, exact: true, layoutProps: { MenuID_Selected: 8, sTitle: "องค์ความรู้" } },
    { path: "/admin-knowledge-sub-list", component: Admin_knowledge_sub_list2, layout: MP_Back, exact: true, layoutProps: { MenuID_Selected: 8, sTitle: "องค์ความรู้" } },
    { path: "/admin-knowledge-edit", component: Admin_knowledge_edit, layout: MP_Back, exact: true, layoutProps: { MenuID_Selected: 8, sTitle: "องค์ความรู้" } },

    { path: "/admin-event-edit", component: admin_event_edit, layout: MP_Back, exact: true, layoutProps: { MenuID_Selected: 12, sTitle: "กิจกรรม" } },
    { path: "/admin-event-list", component: admin_event_list, layout: MP_Back, exact: true, layoutProps: { MenuID_Selected: 12, sTitle: "กิจกรรม" } },

    { path: "/admin-cms-list", component: Admin_CMS_List, layout: MP_Back, exact: true, layoutProps: { MenuID_Selected: 26, sTitle: "CMS" } },
    { path: "/admin-cms", component: Admin_CMS, layout: MP_Back, exact: true, layoutProps: { MenuID_Selected: 26, sTitle: "CMS" } },
    { path: "/report-manage-information", component: Report_Manage_Information, layout: MP_Back, exact: true, layoutProps: { MenuID_Selected: 40, sTitle: "รายงานบริหารจัดการข้อมูลบนเว็บไซต์" } },
    { path: "/report-forest-map-data", component: Report_Forest_Map_Data, layout: MP_Back, exact: true, layoutProps: { MenuID_Selected: 43, sTitle: "รายงานข้อมูลแผนที่ป่า" } },
    { path: "/report-like-and-share", component: Report_Like_And_Share, layout: MP_Back, exact: true, layoutProps: { MenuID_Selected: 42, sTitle: "รายงานการกดไลค์กดแชร์บนเว็บไซต์" } },
    { path: "/report-traffic-statistics", component: Report_Traffic_Statistics, layout: MP_Back, exact: true, layoutProps: { MenuID_Selected: 41, sTitle: "รายงานสถิติการเข้าชมเว็บไซต์" } },


    { path: "/admin-subscribe-edit", component: Admin_Subscribe_Edit, layout: MP_Back, exact: true, layoutProps: { MenuID_Selected: 44, sTitle: "ผู้ติดตาม" } },
    { path: "/admin-subscribe-list", component: Admin_Subscribe_List, layout: MP_Back, exact: true, layoutProps: { MenuID_Selected: 44, sTitle: "ผู้ติดตาม" } },

]

export default class App extends React.Component {
    render() {
        return (
            <Router history={history}>
                <Switch>
                    {route.map((o) => {
                        return (
                            <Route path={o.path} key={o.path} exact={o.exact}>
                                {o.layout ? <o.layout {...o.layoutProps}><o.component /></o.layout> : <o.component />}
                            </Route>
                        );
                    })}
                </Switch>
            </Router>
        );
    }
};

//export default () => (
//    <Layout>
//        <Route exact path='/' component={Home} />
//        <Route path='/counter' component={Counter} />
//        <Route path='/fetch-data/:startDateIndex?' component={FetchData} />
//    </Layout>
//);