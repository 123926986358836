import * as React from "react";
import { Link } from "react-router-dom";
import { createBrowserHistory } from 'history';
import NavBar from "./LayoutMain_Menu";
import * as ILayoutMain from "./interface";
import { AxiosGetJson, AxiosPostJson } from "../Service/Config/AxiosMethod";
import { Input, Button, Tooltip, Collapse, Radio } from "antd";
import BlockUI from 'react-block-ui';
import 'react-block-ui/style.css';
import "./LayoutMain.css";
import { useSelector, useDispatch } from 'react-redux';
import {
    thailanguage,
    englishlanguage,
    currentlanguage,
} from '../app/counterSlice';
import { BoxMsg, DialogConfirm, Responsestart, Sweetalert } from "../Systems/SystemComponent";
import { parse } from "query-string"
import { Helmet } from "react-helmet"

const { Panel } = Collapse;

const baseUrl = document
    .getElementsByTagName("base")[0]
    .getAttribute("href") as string;
const history = createBrowserHistory({ basename: baseUrl });

interface IState {
    st_ActiveBlock: boolean;
    MenuSource: Array<ILayoutMain.Menu>;
    sTxtSearch: string;
}

const LayoutMain = (props: any) => {
    var { id } = parse(window.location.search);
    const [st_ActiveBlock, St_ActiveBlock_SetValue] = React.useState(false);
    const [MenuSource, MenuSource_SetValue] = React.useState<Array<ILayoutMain.Menu>>([]);
    const [sTxtSearch, sTxtSearch_SetValue] = React.useState("");
    const [sEmail, setEmail] = React.useState("");
    const translate = useSelector(currentlanguage);
    const dispatch = useDispatch();
    const [CrrSocial, setCrrSocial] = React.useState(0 as number)
    const [T_Social, setT_Social] = React.useState([] as Array<any>)
    const [T_SocialUrl, setT_SocialUrl] = React.useState([] as Array<any>)
    const [click, setclick] = React.useState(false as boolean)
    const [lstIndex, setLstIndex] = React.useState([] as any);
    const [isShowThEn, setShowThEn] = React.useState(true);

    let MenuID_Selected: Number = parseInt(props.MenuID_Selected) || 0;

    let Element = {
        BODY: document.getElementsByTagName("body")[0],
        BODY_AddClass: (sClass: string) => {
            Element.BODY.classList.add(sClass);
        },
        BODY_RemoveClass: (sClass: string) => {
            Element.BODY.classList.remove(sClass);
        },
    };

    let NAVBAR_Expand = () => {
        Element.BODY_AddClass("nav-expanded");
    };
    let NAVBAR_Collapse = () => {
        Element.BODY_RemoveClass("nav-expanded");
    };

    let HeaderSearch = React.createRef<HTMLDivElement>();
    let HeaderSearch_Toggle = () => {
        if (HeaderSearch.current) {
            if (HeaderSearch.current.classList.contains('collapsed')) HeaderSearch.current.classList.remove('collapsed');
            else HeaderSearch.current.classList.add('collapsed');
        }
    }

    let PanelContact = React.createRef<HTMLDivElement>();
    let PanelContact_Toggle = () => {
        if (PanelContact.current) {
            if (PanelContact.current.classList.contains('panel')) PanelContact.current.classList.remove('panel');
            else PanelContact.current.classList.add('panel');
        }
    }

    const onAllSearch = () => {
        window.open("/search?key=" + sTxtSearch, "_self");
    }

    const onOpenContactUs = () => {
        window.open("/main-contact-us", "_self");
    }

    const onCheckThEn = async () => {
        var sPathName = window.location.pathname.replace("/", "");
        var idStr: string = "";
        if (id !== undefined && id !== null) {
            idStr = id.toString().split("/").join("%2f");
        }
        else {
            idStr = "None";
        }
        let isShow = await AxiosGetJson("api/AdminInfo/CheckThEn?Selected=" + MenuID_Selected + "&sPathName=" + sPathName + "&id=" + idStr);
        setShowThEn(isShow);
    }

    //new 

    const UserInfo_SetState = async (TranslatType) => {
        let listv_content_category = await AxiosGetJson("api/Home/GetMenu?Mode=" + TranslatType);

        if (listv_content_category !== null && listv_content_category.length > 0) {
            let arrMenu: Array<ILayoutMain.Menu> = [];


            let menumain = listv_content_category.filter(f => f.nMenuID !== null)

            let partimg = [
                // { nMenuID: 1, icon: "<img src='" + require('../../Images/menu/ico-home.png') + "' />" },
                // { nMenuID: 2, icon: "<img src='" + require('../../Images/menu/ico-academy.png') + "' />" },
                // { nMenuID: 3, icon: "<img src='" + require('../../Images/menu/ico-announce.png') + "' />" },
                // { nMenuID: 4, icon: "<img src='" + require('../../Images/menu/ico-bird.png') + "' />" },
                // { nMenuID: 5, icon: "<img src='" + require('../../Images/menu/ico-learn.png') + "' />" },
                // { nMenuID: 6, icon: "<img src='" + require('../../Images/menu/ico-star.png') + "' />" },
                // { nMenuID: 7, icon: "<img src='" + require('../../Images/menu/ico-knowledge.png') + "' />" },
                // { nMenuID: 8, icon: "<img src='" + require('../../Images/menu/ico-compass.png') + "' />" },
                { nMenuID: 1, icon: "<i class=icofont-home></i>" },
                { nMenuID: 2, icon: "<i class=icofont-info-circle></i>" },
                { nMenuID: 3, icon: "<i class=icofont-bullhorn></i>" },
                { nMenuID: 4, icon: "<i class=icofont-pigeon-2></i>" },
                { nMenuID: 5, icon: "<i class=icofont-read-book></i>" },
                { nMenuID: 6, icon: "<i class=icofont-favourite></i>" },
                { nMenuID: 7, icon: "<i class=icofont-read-book-alt></i>" },
                { nMenuID: 8, icon: "<i class=icofont-ui-map></i>" },
            ]
            menumain.forEach(m => {
                let Lv1: any = [];
                let childrenLv1 = listv_content_category.filter(f => f.nHeadTypeID == m.nTypeID);
                if (childrenLv1.length > 0) {
                    childrenLv1.forEach(m => {
                        let Lv2: any = [];
                        let childrenLv2 = listv_content_category.filter(f => f.nHeadTypeID == m.nTypeID);
                        if (childrenLv2.length > 0) {
                            childrenLv2.forEach(m => {
                                Lv2.push({
                                    label: translate.TranslatType === "TH" ? m.sName : m.sNameEng,
                                    to: m.sURL,
                                    active: props.MenuID_Selected === m.nMenuID,
                                });

                            });
                        };
                        Lv1.push({
                            label: translate.TranslatType === "TH" ? m.sName : m.sNameEng,
                            to: m.sURL,
                            active: props.MenuID_Selected === m.nMenuID,
                            children: Lv2.length === 0 ? undefined : Lv2,
                        });

                    });
                };
                arrMenu.push({
                    label: translate.TranslatType === "TH" ? m.sName : m.sNameEng,
                    icon: partimg[m.nMenuID - 1].icon,
                    to: m.sURL,
                    active: props.MenuID_Selected === m.nMenuID,
                    children: Lv1.length === 0 ? undefined : Lv1,
                });
            });
            MenuSource_SetValue(arrMenu);
        }
    };

    const onSubscribe = (isSub) => {
        if (translate.TranslatType === "TH") {
            Sweetalert.Confirm(translate.Confirm,
                isSub ? translate.ConfirmSubscribe : translate.ConfirmUnsubscribe,
                async () => {
                    var data =
                    {
                        sEmail: sEmail,
                        isSub: isSub,
                        Mode: translate.TranslatType
                    }
                    let result: any = await AxiosPostJson("api/Home/SaveSubscribe", data);
                    if (result.data.sStatus === Responsestart.success) {
                        Sweetalert.Success(BoxMsg.Title_Success, result.data.sMsg, null);
                        setEmail("");
                    } else if (result.data.sStatus === Responsestart.warning) {
                        Sweetalert.Warning(BoxMsg.Title_Warning, result.data.sMsg, null);
                    } else {
                        Sweetalert.Error(BoxMsg.Title_Error, result.data.sMsg, null);
                    }
                }, undefined);
        }
        else {
            Sweetalert.ConfirmEN(translate.Confirm,
                isSub ? translate.ConfirmSubscribe : translate.ConfirmUnsubscribe,
                async () => {
                    var data =
                    {
                        sEmail: sEmail,
                        isSub: isSub,
                        Mode: translate.TranslatType
                    }
                    let result: any = await AxiosPostJson("api/Home/SaveSubscribe", data);
                    if (result.data.sStatus === Responsestart.success) {
                        Sweetalert.SuccessEN(translate.Success, result.data.sMsg, null);
                        setEmail("");
                    } else if (result.data.sStatus === Responsestart.warning) {
                        Sweetalert.WarningEN(translate.Warning, result.data.sMsg, null);
                    } else {
                        Sweetalert.ErrorEN(translate.Error, result.data.sMsg, null);
                    }
                }, undefined);
        }
    }

    const Getlist_Social = async () => {
        let result: any = await AxiosGetJson("api/Home/" + "Getlist_Social");
        setT_Social(result.T_Social);
        setT_SocialUrl(result.T_SocialUrl);
    }
    React.useEffect(() => {
        UserInfo_SetState(translate.TranslatType);
        Getlist_Social();
        SetBackground();
        GetListIndex(translate.TranslatType);
        onCheckThEn();
        window.scrollTo(0, 0);
    }, [translate]);

    const [sFilePath, setFilePath] = React.useState("");

    const SetBackground = async () => {
        let result: any = await AxiosGetJson("api/AdminInfo/GetBackground");
        setFilePath(result.sFilePath);
    }

    const GetListIndex = async (TranslatType) => {
        let result: any = await AxiosGetJson("api/AdminInfo/GetListIndex?type=5&Mode=" + TranslatType);
        setLstIndex(result);
    }

    return (
        <div id="PAGE_MAIN">
            {/*<Helmet>
                <script src="https://cdn-apac.onetrust.com/scripttemplates/otSDKStub.js" data-language="th" data-document-language="true" type="text/javascript" charSet="UTF-8" data-domain-script="c402e90c-e8ae-4607-8dc2-b15992e702d5-test"></script>
                <script type="text/javascript">
                    function OptanonWrapper() { }
                </script>
            </Helmet>*/}
            {
                //translate.TranslatType === "TH" ?
                //<>
                //    <Helmet>
                //        <script src="https://cdn-apac.onetrust.com/scripttemplates/otSDKStub.js" data-language="th" data-document-language="true" type="text/javascript" charSet="UTF-8" data-domain-script="c402e90c-e8ae-4607-8dc2-b15992e702d5-test"></script>
                //        <script type="text/javascript">
                //            function OptanonWrapper() { }
                //        </script>
                //    </Helmet>
                //</>
                //:
                //<>
                //    <Helmet>
                //        <script src="https://cdn-apac.onetrust.com/scripttemplates/otSDKStub.js" data-language="en" data-document-language="true" type="text/javascript" charSet="UTF-8" data-domain-script="c402e90c-e8ae-4607-8dc2-b15992e702d5-test"></script>
                //        <script type="text/javascript">
                //            function OptanonWrapper() { }
                //        </script>
                //    </Helmet>
                //</>
                <Helmet htmlAttributes={{ lang: translate.TranslatType }} />
            }
            <BlockUI blocking={st_ActiveBlock}></BlockUI>
            <div id="first">
            </div>
            <div id="SITE_NAV">
                <NavBar id="navSIDE" source={MenuSource} isSide={true} />
            </div>
            <div id="SITE_CONTENT">
                <div className="s-header">
                    <a className="header-title" href="/home">
                        <div className="title-icon">
                            <img src={require('../../Images/ico-ptt-white.png')} />
                        </div>
                        <div className="title-label">
                            <div className="label-1">{translate.Title1}</div>
                            <div className="label-2">{translate.Title2}</div>
                        </div>
                    </a>
                    <div className="header-content">
                        <div className="row justify-content-end align-items-center mx-0">
                            <div className="col">
                                <div className="header-contact">
                                    {/* <div className="contact-item">
                                        <b ><a style={{ color: "#FFFFFF" }} onClick={() => onOpenContactUs()}>{translate.ContactUsHome}</a></b>
                                    </div>
                                    <div className="contact-item">
                                        <a className={click && CrrSocial === 1 ? "facebook contact-item-icon active" : "facebook contact-item-icon"} onClick={() => { PanelContact_Toggle(); setCrrSocial(1); setclick(!click) }} >
                                            <i className="icofont-facebook"></i>
                                        </a>
                                        <a className={click && CrrSocial === 15 ? "line contact-item-icon active" : "line contact-item-icon"} onClick={() => { PanelContact_Toggle(); setCrrSocial(15); setclick(!click) }} >
                                            <i className="icofont-line"></i>
                                        </a>
                                        <a className={click && CrrSocial === 4 ? "ins contact-item-icon active" : "ins contact-item-icon"} onClick={() => { PanelContact_Toggle(); setCrrSocial(4); setclick(!click) }} >
                                            <i className="icofont-instagram"></i>
                                        </a>
                                        <a className={click && CrrSocial === 2 ? "youtube contact-item-icon active" : "youtube contact-item-icon"} onClick={() => { PanelContact_Toggle(); setCrrSocial(2); setclick(!click) }} >
                                            <i className="icofont-youtube-play"></i>
                                        </a>
                                    </div> */}
                                    <div className="contact-us">
                                        <div className="row">
                                            <div className="col-lg-auto col-md-12 col-sm-12" style={{ padding: "0" }}>
                                                <b onClick={() => onOpenContactUs()} >{translate.ContactUsHome}</b>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="contact-item">
                                        <div className="row">
                                            <div className="col-lg-auto col-md-12 col-sm-12" style={{ paddingTop: "2px" }}><b ><a style={{ color: "#FFFFFF" }} onClick={() => onOpenContactUs()}>{translate.ContactUsHome}</a></b></div>

                                            <div className="col-lg-auto col-md-12 col-sm-12" style={{ padding: "0" }}>

                                                {
                                                    T_Social.filter(f => f.nSocialID === 1).length > 0 ?
                                                        <a className={click && CrrSocial === 1 ? "facebook contact-item-icon active" : "facebook contact-item-icon"} onClick={() => { PanelContact_Toggle(); setCrrSocial(1); setclick(!click) }} >
                                                            <i className="icofont-facebook"></i>
                                                        </a>
                                                        :
                                                        null
                                                }

                                                {
                                                    T_Social.filter(f => f.nSocialID === 2).length > 0 ?
                                                        <a className={click && CrrSocial === 2 ? "line contact-item-icon active" : "line contact-item-icon"} onClick={() => { PanelContact_Toggle(); setCrrSocial(2); setclick(!click) }} >
                                                            <i className="icofont-line"></i>
                                                        </a>
                                                        :
                                                        null
                                                }

                                                {
                                                    T_Social.filter(f => f.nSocialID === 3).length > 0 ?
                                                        <a className={click && CrrSocial === 3 ? "ins contact-item-icon active" : "ins contact-item-icon"} onClick={() => { PanelContact_Toggle(); setCrrSocial(3); setclick(!click) }} >
                                                            <i className="icofont-instagram"></i>
                                                        </a>
                                                        :
                                                        null
                                                }

                                                {
                                                    T_Social.filter(f => f.nSocialID === 4).length > 0 ?
                                                        <a className={click && CrrSocial === 4 ? "youtube contact-item-icon active" : "youtube contact-item-icon"} onClick={() => { PanelContact_Toggle(); setCrrSocial(4); setclick(!click) }} >
                                                            <i className="icofont-youtube-play"></i>
                                                        </a>
                                                        :
                                                        null
                                                }


                                                <div ref={PanelContact} className="conteact-panel panel">
                                                    <div className="container-fluid">
                                                        <div className="form-row" style={{ marginRight: "15px"}}>
                                                            <div className="col">
                                                                <ul>
                                                                    {
                                                                        T_SocialUrl.length > 0 ?
                                                                            T_SocialUrl.filter(w => w.nSocialID === CrrSocial).map((o) => {
                                                                                return (
                                                                                    <li>
                                                                                        <a onClick={() => { window.open(o.sUrl, "_blank") }}>
                                                                                            {translate.TranslatType === "TH" ? o.sDetail : o.sDetail_EN}
                                                                                        </a>
                                                                                    </li>
                                                                                )
                                                                            })
                                                                            :
                                                                            null
                                                                    }
                                                                </ul>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>


                                    </div>


                                    {/* <div className="contact-item">
                                        <b>{translate.Email} :</b> pttrfi@pttplc.com
                                    </div>
                                    <div className="contact-item">
                                        <b>{translate.Tel} :</b> 02-5373354
                                    </div> */}
                                    <div className="contact-item">
                                        <div className="row">
                                            <div className="col-lg-3 col-md-12 col-sm-12"><b>{translate.Email}</b></div>
                                            <div className="col-lg-9 col-md-12 col-sm-12">pttrfi@pttplc.com</div>
                                        </div>
                                    </div>
                                    <div className="contact-item">
                                        <div className="row">
                                            <div className="col-lg-3 col-md-12 col-sm-12"><b>{translate.Tel}</b></div>
                                            <div className="col-lg-9 col-md-12 col-sm-12">02-5373354</div>
                                        </div>
                                    </div>






                                </div>
                            </div>
                            <div className="col-auto p-0">
                                {
                                    isShowThEn ?
                                        <Radio.Group defaultValue={translate.TranslatType} buttonStyle="solid" className="language-toggler">
                                            <Radio.Button value="TH" onChange={() => { dispatch(thailanguage()); window.location.reload(); }}>TH</Radio.Button>
                                            <Radio.Button value="EN" onChange={() => { dispatch(englishlanguage()); window.location.reload(); }}>EN</Radio.Button>
                                        </Radio.Group>
                                        :
                                        null
                                }
                            </div>
                        </div>
                        <div className="header-nav">
                            <div className="nav-col d-none d-xl-block">
                                <NavBar id="navTOP" source={MenuSource} />
                            </div>
                            <div className="nav-col d-xl-none ml-auto">
                                <button type="button" className="nav-btn btn-default" onClick={NAVBAR_Expand}>
                                    <i className="fa fa-bars"></i>
                                </button>
                            </div>
                            <div className="nav-col">
                                <button type="button" className="nav-btn btn-search" onClick={HeaderSearch_Toggle}>
                                    <i className="fa fa-search"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div ref={HeaderSearch} className="header-search collapsed">
                        <div className="container-fluid">
                            <div className="form-row">
                                <div className="col">
                                    <Input autoComplete="off" onChange={(e) => {
                                        sTxtSearch_SetValue(e.target.value);
                                    }} placeholder={translate.PlaceHolderSearch} />
                                </div>
                                <div className="col-auto">
                                    <Button type="text" className="btn-dark rounded-pill" onClick={onAllSearch}><i className="fa fa-search"></i>&nbsp;{translate.Search}</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="s-body" style={{ backgroundImage: "url(" + sFilePath + ")", }}>{props.children}</div>
                <div className="s-footer">
                    <div className="footer-subscribe">
                        <div className="d-flex justify-content-center">
                            <div className="col-auto">
                                <div className="text-white">{translate.Subscription}</div>
                                <div className="form-row">
                                    <div className="col-auto">
                                        <Input value={sEmail} autoComplete="off" onChange={e => {
                                            setEmail(e.target.value);
                                        }} placeholder={translate.PlaceHolderEmail} suffix={<i className="far fa-envelope"></i>} />
                                    </div>
                                    <div className="col-auto">
                                        <Button type="text" className="text-white" onClick={() => onSubscribe(true)}><i className="fa fa-bell"></i>&nbsp;{translate.Subscript}</Button>
                                        <Button type="text" className="text-white" onClick={() => onSubscribe(false)}><i className="fa fa-bell-slash"></i>&nbsp;{translate.UnSubscript}</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-sitemap">
                        <Collapse bordered={false}>
                            <Panel
                                header={
                                    <div className="panel-sitemap">
                                        <div className="sitemap-title">{translate.SiteMap}</div>
                                        <div className="content-private ml-auto">
                                            <a href="https://pttpdpa.pttplc.com/Privacy/154159" target="_blank" className="private-title">{translate.PrivacyPolicy}</a>
                                            <a href="/cookies-policy" target="_blank" className="private-desc">{translate.CookiesPolicy}</a>
                                        </div>
                                    </div>
                                }
                                key="1"
                            >
                                {/* <Panel header={translate.SiteMap} key="1"> */}
                                <div className="sitemap-body">
                                    {
                                        MenuSource.length > 0 ?
                                            <React.Fragment>
                                                <div className="sitemap-menu">
                                                    {
                                                        MenuSource.filter(f => f.children === undefined).map((nc, inc) => {
                                                            return (
                                                                <a className="menu-head" key={"nc_" + inc} href={nc.to}>{nc.label}</a>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                {
                                                    MenuSource.filter(f => f.children !== undefined).map((sm, ism) => {
                                                        return (
                                                            <div className="sitemap-menu" key={"sm_" + ism}>
                                                                <a className="menu-head" href={sm.to}>{sm.label}</a>
                                                                {
                                                                    sm.children && sm.children.length > 0 ?
                                                                        sm.children.map((smc, ismc) => {
                                                                            return (
                                                                                <a className="menu-item" key={"smc_" + ism + "_" + ismc} href={smc.to}>{smc.label}</a>
                                                                            )
                                                                        })
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </React.Fragment>
                                            :
                                            null
                                    }
                                    {/*
                                            <div className="sitemap-menu">
                                                <a className="menu-head">Head Menu</a>
                                                <a className="menu-item">Sub Menu 1</a>
                                                <a className="menu-item">Sub Menu 2</a>
                                                <a className="menu-item">Sub Menu 3</a>
                                                <a className="menu-item">Sub Menu 4</a>
                                            </div>
                                            <div className="sitemap-menu">
                                                <a className="menu-head">Head Menu</a>
                                                <a className="menu-item">Sub Menu 1</a>
                                                <a className="menu-item">Sub Menu 2</a>
                                                <a className="menu-item">Sub Menu 3</a>
                                                <a className="menu-item">Sub Menu 4</a>
                                            </div>
                                            <div className="sitemap-menu">
                                                <a className="menu-head">Head Menu</a>
                                                <a className="menu-item">Sub Menu 1</a>
                                                <a className="menu-item">Sub Menu 2</a>
                                                <a className="menu-item">Sub Menu 3</a>
                                                <a className="menu-item">Sub Menu 4</a>
                                            </div>
                                            <div className="sitemap-menu">
                                                <a className="menu-head">Head Menu</a>
                                                <a className="menu-item">Sub Menu 1</a>
                                                <a className="menu-item">Sub Menu 2</a>
                                                <a className="menu-item">Sub Menu 3</a>
                                                <a className="menu-item">Sub Menu 4</a>
                                            </div>
                                            <div className="sitemap-menu">
                                                <a className="menu-head">Head Menu</a>
                                                <a className="menu-item">Sub Menu 1</a>
                                                <a className="menu-item">Sub Menu 2</a>
                                                <a className="menu-item">Sub Menu 3</a>
                                                <a className="menu-item">Sub Menu 4</a>
                                            </div>
                                            <div className="sitemap-menu">
                                                <a className="menu-head">Head Menu</a>
                                                <a className="menu-item">Sub Menu 1</a>
                                                <a className="menu-item">Sub Menu 2</a>
                                                <a className="menu-item">Sub Menu 3</a>
                                                <a className="menu-item">Sub Menu 4</a>
                                            </div>
                                            <div className="sitemap-menu">
                                                <a className="menu-head">Head Menu</a>
                                                <a className="menu-item">Sub Menu 1</a>
                                                <a className="menu-item">Sub Menu 2</a>
                                                <a className="menu-item">Sub Menu 3</a>
                                                <a className="menu-item">Sub Menu 4</a>
                                            </div>
                                            <div className="sitemap-menu">
                                                <a className="menu-head">Head Menu</a>
                                                <a className="menu-item">Sub Menu 1</a>
                                                <a className="menu-item">Sub Menu 2</a>
                                                <a className="menu-item">Sub Menu 3</a>
                                                <a className="menu-item">Sub Menu 4</a>
                                            </div>
                                            <div className="sitemap-menu">
                                                <a className="menu-head">Head Menu</a>
                                                <a className="menu-item">Sub Menu 1</a>
                                                <a className="menu-item">Sub Menu 2</a>
                                                <a className="menu-item">Sub Menu 3</a>
                                                <a className="menu-item">Sub Menu 4</a>
                                            </div>
                                        */}
                                </div>
                            </Panel>
                        </Collapse>
                    </div>
                    <div className="footer-content">
                        <div className="content-institution">
                            <a href="https://pttplc.com/th/Home.aspx" target="_blank"><img src={require("../../Images/ico-ptt.png")} className="ico-ptt" /></a>
                            <a href="/home"><img src={require("../../Images/institution/institution-1.png")} className="ico-institution" /></a>
                            {
                                lstIndex.length > 0 ?
                                    lstIndex.map((i, indx) => {
                                        return (
                                            <a key={"lc_" + indx} href={i.sUrlVisit} target="_blank"><img src={i.sImageUrl} className="ico-institution" /></a>
                                        );
                                    })
                                    : null
                            }
                            {/*<a href="/learning-center-sub?id=JlzyfAmI%2fyvKf8fe942mrw=="><img src={require("../../Images/institution/institution-2.png")} className="ico-institution" /></a>
                            <a href="/learning-center-sub?id=dd3Z7geQzgJRvlNY5VwSiQ=="><img src={require("../../Images/institution/institution-3.png")} className="ico-institution" /></a>
                            <a href="/learning-center-sub?id=cN98tWM0Ulsc0l6YtH5bNw=="><img src={require("../../Images/institution/institution-4.png")} className="ico-institution" /></a>*/}
                        </div>
                        {/* <div className="content-private">
                            <a href="https://pttpdpa.pttplc.com/Privacy/154159" target="_blank" className="private-title">{translate.PrivacyPolicy}</a>
                            <a href="/cookies-policy" target="_blank" className="private-desc">{translate.CookiesPolicy}</a>
                        </div> */}
                        <div className="content-contact">
                            <div className="contact-title">{translate.CompanyName}</div>
                            <div className="contact-address">{translate.CompanyAddress}</div>
                        </div>
                        {/* <div className="contact-panel">
                            <div className="contact-private">
                                <a href="https://pttpdpa.pttplc.com/Privacy/154159" target="_blank" className="private-title">{translate.PrivacyPolicy}</a>
                                <a href="/cookies-policy" target="_blank" className="private-desc">{translate.CookiesPolicy}</a>
                            </div>
                            <div className="content-contact">
                                <div className="contact-title">{translate.CompanyName}</div>
                                <div className="contact-address">{translate.CompanyAddress}</div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="s-block" onClick={NAVBAR_Collapse}>
                    <div className="block-label">
                        <i className="fa fa-exchange-alt"></i>
                    </div>
                </div>
            </div>
            <Link id="lnkLogin" to="/login" hidden={true}></Link>
            <Link id="lnkHome" to="/home" hidden={true}></Link>
        </div>
    );
}
export default LayoutMain;

