import * as React from "react";
import { BoxMsg, DialogConfirm, Responsestart, Sweetalert } from "../Systems/SystemComponent";
import "./Login.css";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import * as QS from "querystring";
import { AuthToken } from "../Service/Config/AuthToken";
import AuthenService from "../Service/AuthenService";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import * as Antd from "antd";
import { Col, Form, Input, Radio, Row } from "antd";
import { ButtonCancel, ButtonConfirm } from "../Systems/button_all";
import { AxiosPostJson } from "../Service/Config/AxiosMethod";
import { parse } from "query-string"

const Login = () => {
    const history = useHistory();
    const [SetValue] = Antd.Form.useForm();
    const [st_CapsLock, setActiveCapsLock] = React.useState<Boolean>();
    const [st_ActiveBlock, setActiveBlock] = React.useState(false);
    const [isModalOpen, setModalOpen] = React.useState(false);

    const Input_GetValue = (_inputID: string) => {
        var _val: string | undefined;
        var _input = document.getElementById(_inputID) as HTMLInputElement | null;
        if (_input) _val = _input.value || "";
        return _val;
    };

    const formLogin_onSubmit = () => {
        var _Username = Input_GetValue("txtUsername");
        var _Password = Input_GetValue("txtPassword");
        if (_Username && _Password) Login_Action(_Username, _Password);
        else Sweetalert.Warning(BoxMsg.Title_Warning, "กรุณากรอกรหัสผู้ใช้งานและรหัสผ่าน", () => { setActiveBlock(false); });
    };

    const Login_Action = async (_Username: string, _Password: string) => {
        let qryStr = QS.parse(window.location.search);
        let sRequestID = qryStr["r"] ? qryStr["r"] + "" : undefined;
        let obj = { sUsername: _Username, sPassword: _Password, sRequestID: sRequestID };
        const response = await AuthenService.SignIn(obj);
        const token = response.data.token;
        if (token) {
            AuthToken.set(token);
            var loc: string = (response.data.url_redirect || "admin-intropage-list");
            var { ref, id } = parse(window.location.search);
            var refStr: string = "";
            if (ref !== undefined && ref !== null) {
                setActiveBlock(true);
                refStr = "?ref=" + ref.toString();
                if (id !== undefined && id !== null) {
                    refStr = refStr + "&id=" + id.toString().split("/").join("%2f");
                }
            }
            //history.push("/" + (response.data.url_redirect || "home"));
            history.push("/" + loc + refStr);
        }
        else {
            AuthToken.set(null);
            Sweetalert.Warning(BoxMsg.Title_Warning, response.data.message, () => {
                setActiveBlock(false);
            })
        };
    };

    const validateMessages = {
        required: '${label} is required!',
        types: {
            email: '${label} is not a valid email!',
            number: '${label} is not a valid number!',
        },
        number: {
            range: '${label} must be between ${min} and ${max}',
        },
    };

    const onResetPassword = (values: any) => {
        DialogConfirm(async () => {
            var dat = {
                sUsername: values.sUsername,
                sEmail: values.sEmail
            }
            let result: any = await AxiosPostJson("api/User/ResetPassword", dat);
            if (result.data.sStatus === Responsestart.success) {
                Sweetalert.Success(BoxMsg.Title_Success, "Reset password complete and send new password to " + values.sEmail, () => {
                    SetValue.resetFields();
                    setModalOpen(false);
                });
            } else if (result.data.sStatus === Responsestart.warning) {
                Sweetalert.Warning(BoxMsg.Title_Warning, result.data.sMsg, null);
            } else {
                Sweetalert.Error(BoxMsg.Title_Error, result.data.sMsg, null);
            }
        },
            () => { return; },
            BoxMsg.Title_Confirm, "Do you want to reset password?");
    };

    const txtUsername_onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.which === 13 && e.currentTarget.value) {
            var e_txtPassword = document.getElementById("txtPassword");
            if (e_txtPassword) e_txtPassword.focus();
            return false;
        }
        else if (e.which === 220) return false;
    };

    const txtPassword_onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.which === 13) {
            formLogin_onSubmit();
            setActiveBlock(true);
            return false;
        }
        else if (e.which === 220) return false;
    };

    const LoginAD = async (data) => {
        const response = await AuthenService.SignInAD(data);
        const token = response.data.token;
        if (token) {
            AuthToken.set(token);
            history.push("/" + (response.data.url_redirect || "home"));
        }
        else {
            AuthToken.set(null);
            Sweetalert.Warning(BoxMsg.Title_Warning, response.data.message, () => {
                setActiveBlock(false);
            })
        };
    }

    React.useEffect(() => {
        document.addEventListener("mouseenter", (e: MouseEvent) => { try { setActiveCapsLock(e.getModifierState("CapsLock")) } catch { } });
        document.addEventListener("click", (e: MouseEvent) => { try { setActiveCapsLock(e.getModifierState("CapsLock")) } catch { } });
        document.addEventListener("keyup", (e: KeyboardEvent) => { try { setActiveCapsLock(e.getModifierState("CapsLock")) } catch { } });
        var { emp, ref, id } = parse(window.location.search);
        var empStr: string = "";
        var refStr: string = "";
        var idStr: string = "";
        if ((ref !== undefined && ref !== null) && (emp !== undefined && emp !== null)) {
            setActiveBlock(true);
            refStr = ref.toString();
            empStr = emp.toString();
            if (id !== undefined && id !== null) {
                idStr = id.toString().split("/").join("%2f");
            }
            var data = {
                emp: empStr,
                loc: refStr,
                id: idStr
            }
            LoginAD(data);
        }
    }, [])

    return (
        <React.Fragment>
            <div id="LOGIN">
                <BlockUi blocking={st_ActiveBlock}></BlockUi>
                <div className="box-overview">
                    <div className="box-cover"></div>
                </div>
                <form className="box-login">
                    <img
                        src={require("../../Images/ico-ptt-white.png")}
                        className="mb-3"
                        style={{ marginLeft: -10, width: "50%" }}
                    />
                    {/* <div className="box-title title-2">Reforestation</div> */}
                    <br />
                    <div className="box-title title-1">Reforestation &amp; <br/> Ecology Institute</div>
                    <div className="form-row align-items-center">
                        <div className="col-auto">
                            <b style={{ color: "white" }}  >ลงชื่อเข้าใช้</b>
                        </div>
                    </div>
                    <div className="form-group mt-2">
                        <div className="login-input">
                            <i className="fas fa-users" style={{ color: "#000000" }}></i>
                            <input
                                id="txtUsername"
                                type="text"
                                placeholder="รหัสผู้ใช้งาน"
                                autoComplete="off"
                                onKeyPress={txtUsername_onKeyPress}
                            />
                        </div>
                    </div>
                    <div className="form-group mb-3">
                        <div className="login-input">
                            <i className="fas fa-unlock" style={{ color: "#000000" }}></i>
                            <input
                                id="txtPassword"
                                type="password"
                                placeholder="รหัสผ่าน"
                                autoComplete="off"
                                onKeyPress={txtPassword_onKeyPress}
                            />
                        </div>
                    </div>
                    <div className={`form-row align-items-center`} style={{ display: "block" }}>
                        <div className="col-auto mr-auto">
                            {
                                st_CapsLock ? (
                                    <React.Fragment>
                                        <span className="badge badge-pill badge-danger">
                                            <i className="fa fa-exclamation"></i>
                                        </span>{" "}
                                        Caps Lock ถูกเปิดใช้งาน!
                                    </React.Fragment>
                                ) :
                                    <React.Fragment>
                                        &nbsp;
                                    </React.Fragment>
                            }
                        </div>
                    </div>
                    <div className="form-row align-items-center mb-4">
                        {/*<div className="col-auto">
                            <label style={{ color: "#0366d6" }}
                                onClick={() => {
                                    setModalOpen(true);
                            }}>
                                Reset Password
                            </label>
                        </div>*/}
                        <div className="col-auto ml-auto">
                            <button id="btnLogin" type="button" className="btn btn-success" onClick={() => {
                                setActiveBlock(true);
                                formLogin_onSubmit();
                            }
                            }>
                                เข้าสู่ระบบ <i className="fa fa-arrow-right"></i>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div>
                <Modal isOpen={isModalOpen} scrollable size="md">
                    <ModalBody>
                        <b>
                            <i className="fas fa-user-lock"></i> Reset Password
                        </b>
                        <br />
                        <br />
                        <Form name="From" form={SetValue} onFinish={onResetPassword} validateMessages={validateMessages}>
                            <Row gutter={[16, 16]}>
                                <Col xl={24} sm={24} xs={24}>
                                    <b> Username </b>
                                    <span className="text-danger">*</span>
                                    <Form.Item
                                        name="sUsername"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please specify Username",
                                            },
                                        ]}
                                    >
                                        <Input autoComplete="off" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[16, 16]}>
                                <Col xl={24} sm={24} xs={24}>
                                    <b> E-mail </b>
                                    <span className="text-danger">*</span>
                                    <Form.Item
                                        name="sEmail"
                                        rules={[{ required: true, message: BoxMsg.Desc_Required_Field + ' E-mail' },
                                        { type: 'email', message: 'E-mail is not valid' }
                                        ]}
                                    >
                                        <Input autoComplete="off" maxLength={100} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div className="text-right">
                                <ButtonConfirm htmlType="submit" />&nbsp;
                                <ButtonCancel onClick={() => { setModalOpen(false) }} />
                            </div>
                        </Form>
                    </ModalBody>
                </Modal>
            </div>
        </React.Fragment>
    );
};
export default Login;
