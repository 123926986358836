import React, { Fragment, useState } from "react";
import "./MP_Back.css";
import "./MP_Back-custom.css";
import logo from "../../Images/ico-ptt.png";
import { AxiosGetJson, AxiosPostJson } from "../Service/Config/AxiosMethod";
import { Link } from "react-router-dom";
import * as ILayoutMain from "../_Layout/interface";
import NavBar from "../_Layout-Admin/LayoutAdmin_Menu";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Button } from "antd";
import { BoxMsg, Sweetalert } from "../Systems/SystemComponent";
import AuthenService from "../Service/AuthenService";
import { useHistory } from "react-router";


class UserAccount {
    public sEmployeeCode: string = "";
    public sUnitCode: string = "";
    public nRoleID: number = 0;
    public sName: string = "";
    public sPosition: string = "";
    public sOrganization: string = "";
    public sEmail: string = "";
}

const MP_Back = (props: any) => {
    const pathLits = () => {
        let el = document.getElementById("pathLits") as any;
        el && el.click();
    };
    const history = useHistory();
    const [UserInfo, UserInfo_Set] = React.useState<UserAccount>();
    const [AdminMenu, AdminMenu_Set] = useState<Array<ILayoutMain.MenuAdmin>>([]);
    //const [AdminMenu, AdminMenu_Set] = useState<Array<ILayoutMain.Menu>>([
    //    {
    //        labeleng: "Userpermission",
    //        labelthai: "สิทธิผู้ดูแลระบบ",
    //        icon: '<i class="fa fa-users-cog hvr-icon"></i>',
    //        to: "admin-user-permission-list",
    //        active: props.MenuID_Selected === 1,
    //    },
    //    {
    //        labeleng: "News",
    //        labelthai: "ข่าวสาร",
    //        icon: '<i class="fa fa-newspaper hvr-icon"></i>',
    //        to: "admin-news-list",
    //        active: props.MenuID_Selected === 2,
    //    },
    //    {
    //        labeleng: "Banner",
    //        labelthai: "slide ภาพ",
    //        icon: '<i class="fa fa-image hvr-icon"></i>',
    //        to: "admin-banner-list",
    //        active: props.MenuID_Selected === 3,
    //    },
    //    {
    //        labeleng: "Intro",
    //        labelthai: "หน้าเริ่มต้น",
    //        icon: '<i class="far fa-image hvr-icon"></i>',
    //        to: "admin-intropage-list",
    //        active: props.MenuID_Selected === 4,
    //    },
    //    {
    //        labeleng: "Institute",
    //        labelthai: "สถาบัน",
    //        icon: '<i class="fa fa-image hvr-icon"></i>',
    //        to: "admin-institution-edit",
    //        active: props.MenuID_Selected === 5,
    //    },
    //    {
    //        labeleng: "Forest Story",
    //        labelthai: "เรื่องเล่าจากป่า",
    //        icon: '<i class="far fa-image hvr-icon"></i>',
    //        to: "admin-forest-story-list",
    //        active: props.MenuID_Selected === 6,
    //    },
    //    {
    //        labeleng: "Learning Center",
    //        labelthai: "ศูนย์ฯเรียนรู้",
    //        icon: '<i class="far fa-image hvr-icon"></i>',
    //        to: "admin-learning-center-list",
    //        active: props.MenuID_Selected === 7,
    //    },
    //    //{
    //    //    labeleng: "Plants / Animals",
    //    //    labelthai: "พันธุ์ไม้/สัตว์",
    //    //    icon: '<i class="far fa-image hvr-icon"></i>',
    //    //    to: "",
    //    //    active: props.MenuID_Selected === 8,
    //    //},
    //    {
    //        labeleng: "Long-Term Project",
    //        labelthai: "โครงการรักษาป่าระยะยาว",
    //        icon: '<i class="far fa-image hvr-icon"></i>',
    //        to: "admin-lt-project-list",
    //        active: props.MenuID_Selected === 9,
    //    },
    //    {
    //        labeleng: "Highlight Project",
    //        labelthai: "โครงการสำคัญ",
    //        icon: '<i class="far fa-image hvr-icon"></i>',
    //        to: "admin-hl-project-list",
    //        active: props.MenuID_Selected === 10,
    //    },
    //    {
    //        labeleng: "Vetiver Project",
    //        labelthai: "โครงการหญ้าเเฝก",
    //        icon: '<i class="far fa-image hvr-icon"></i>',
    //        to: "admin-v-project-list",
    //        active: props.MenuID_Selected === 10,
    //    },
    //    {
    //        labeleng: "Knowledge",
    //        labelthai: "องค์ความรู้",
    //        icon: '<i class="far fa-image hvr-icon"></i>',
    //        to: "",
    //        active: props.MenuID_Selected === 11,
    //    },
    //    {
    //        labeleng: "PTT Forest Map",
    //        labelthai: "แผนที่ป่า ปตท.",
    //        icon: '<i class="far fa-image hvr-icon"></i>',
    //        to: "admin-map-list",
    //        active: props.MenuID_Selected === 12,
    //    },
    //    {
    //        labeleng: "Customer Service",
    //        labelthai: "ติดต่อสอบถาม",
    //        icon: '<i class="far fa-image hvr-icon"></i>',
    //        to: "admin-contact-us-list",
    //        active: props.MenuID_Selected === 13,
    //    },
    //    {
    //        labeleng: "Contact Us",
    //        labelthai: "ช่องทางการติดต่อ",
    //        icon: '<i class="far fa-image hvr-icon"></i>',
    //        to: "admin-social-list",
    //        active: props.MenuID_Selected === 14,
    //    },
    //]);
    const [NavCollapse, NavCollapse_Set] = React.useState(false);
    const [PageTitle, PageTitle_Set] = React.useState(<b>{props.title}</b>);

    const PageTitle_Read = () => {
        let divIcon: JSX.Element | null = null;
        let arrTitleEng: Array<string> = [];
        let arrTitleThai: Array<string> = [];

        let lstLinkActive = document.querySelectorAll(".main-nav a.active");
        let n = 0;
        lstLinkActive.forEach((m) => {
            let e_icon = m.getElementsByClassName("link-icon");
            let e_labeleng = m.getElementsByClassName("label-eng");
            let e_labelthai = m.getElementsByClassName("label-thai");
            if (n == 0 && e_icon.length)
                divIcon = (
                    <div
                        className="head-icon mr-1"
                        dangerouslySetInnerHTML={{ __html: e_icon[0].innerHTML }}
                    ></div>
                );
            if (e_labeleng.length) arrTitleEng.push(e_labeleng[0].innerHTML);
            n += 1;
            if (e_labelthai.length) arrTitleThai.push(e_labelthai[0].innerHTML);
            n += 1;
        });

        if (arrTitleEng.length) {
            PageTitle_Set(
                <Fragment>
                    {divIcon}
                    {
                        //arrTitleEng.map((s, i) =>
                        //i ? (
                        //    <Fragment key={"HT_" + i}>
                        //        <i className="fa fa-chevron-right mx-1"></i>
                        //        {s}
                        //    </Fragment>
                        //) : (
                        //) : (
                        //    <b
                        //        key={"HT_" + i}
                        //        //style={{ color: "#44c861" }}
                        //    >
                        //        {s || props.title}
                        //    </b>
                        //)
                        //)
                        //& nbsp;<span style={{ fontSize: "20px", fontWeight: 800, marginTop: "5px" }}>/</span>&nbsp;

                    }


                    {arrTitleThai.map((s, i) =>
                        arrTitleThai.length == i + 1 ? (
                            <Fragment key={"HT_" + i}>
                                {
                                    arrTitleThai.length > 1 ? < i className="fa fa-chevron-right mx-1"></i> : null
                                }

                                {
                                    <b style={{ color: "#939393" }}>
                                        {s || props.title}
                                    </b>}
                            </Fragment>
                        ) : (
                            <Fragment key={"HT_" + i}>
                                <b style={{ color: "#939393" }}>
                                    {s || props.title}
                                </b>
                            </Fragment>
                        )
                    )}
                </Fragment>
            );
        }
    };

    const AdjustNav = () => {
        if (window.matchMedia("(max-width: 991px)").matches) {
            if (!NavCollapse) NavCollapse_Set(true);
        } else {
            if (NavCollapse) NavCollapse_Set(false);
        }
    };

    const UserInfo_SetState = async () => {
        try {
            let r: any = await AuthenService.UserInfo();
            if (r.data) {
                var ua = r.data;
                UserInfo_Set({
                    sEmployeeCode: ua.sEmployeeCode,
                    sUnitCode: ua.sUnitCode,
                    nRoleID: ua.nRoleID,
                    sName: ua.sName,
                    sPosition: ua.sPosition,
                    sOrganization: ua.sOrganization,
                    sEmail: ua.sEmail,
                });
            }
        }
        catch { }
    };

    const RedirectToLogin = () => {
        let e_lnkLogin = document.getElementById("lnkLogin");
        if (e_lnkLogin) e_lnkLogin.click();
    }

    const ListAdminMenu = async () => {
        let arrData: Array<any> = await AxiosGetJson("api/AdminUserPermission/ListAdminMenu?nMenuID_Selected=" + (+props.MenuID_Selected || 0));

        function Menu_Build(_arrData: Array<any>) {
            let _arrMenu: Array<ILayoutMain.MenuAdmin> | undefined = undefined;
            if (_arrData ? _arrData.length : false) {
                _arrMenu = _arrData.map(d => ({
                    labeleng: d.sLabelEng,
                    labelthai: d.sLabel,
                    icon: d.sIcon,
                    to: d.sURL,
                    active: d.isSelected,
                    children: Menu_Build(d.lstMenuSub)
                } as ILayoutMain.MenuAdmin));
            }
            return _arrMenu;
        }

        if (arrData.length > 0) {
            let arrMenu: any = Menu_Build(arrData);
            AdminMenu_Set(arrMenu);
        } else {
            Sweetalert.Warning(BoxMsg.Title_Warning, "ไม่มีสิทธิ์เข้าถึง", () => {
                onBack();
            });
        }

    }

    const onBack = () => {
        history.push("/login");
    };


    React.useEffect(() => {
        ListAdminMenu();
        window.addEventListener("resize", AdjustNav);
        AdjustNav();
    }, []);

    React.useEffect(() => {
        UserInfo_SetState();
        PageTitle_Read();
    }, [AdminMenu]);

    return (
        <Fragment>
            <div id="PAGE_ADMIN">
                <div id="SITE_CONTENT">
                    <Link id="lnkLogin" to="/login" hidden={true}></Link>
                    {/*<div className="content-color"></div>*/}
                    <div className="content-header">
                        <div className="header-item">
                            <div className="main-menu_switcher">
                                <button
                                    id="MENU_SWITCHER"
                                    type="button"
                                    onClick={() => {
                                        NavCollapse_Set(!NavCollapse);
                                    }}
                                >
                                    <i className="fa fa-bars"></i>
                                </button>
                            </div>
                            <Link to="/" className="main-title">
                                <div className="logo-web">
                                    <img src={logo} />
                                </div>
                                <div className="title">
                                    {/* <div className="title-1">PTT</div> */}
                                    <div className="title-2">Reforestation &amp; Ecology Institute</div>
                                </div>
                            </Link>
                            <div className="main-info">
                                <div className="info-detail">
                                    <div className="info-title">{UserInfo ? UserInfo.sName : ""}</div>
                                    <div className="info-desc"></div>
                                </div>
                                <div className="info-tool">
                                    {/*<Link to="/">
                                        <Button
                                            type="primary"
                                            shape="circle"
                                            size="small"
                                            style={{
                                                fontSize: "0.85em",
                                                backgroundColor: "#30cab6",
                                                border: "none",
                                            }}
                                        >
                                            <i className="fa fa-home"></i>
                                        </Button>
                                    </Link>{" "}
                                    <Button
                                        type="primary"
                                        danger
                                        shape="circle"
                                        size="small"
                                        style={{ fontSize: "0.85em" }}
                                    >
                                        <i className="fa fa-sign-out-alt"></i>
                                    </Button>
                                    */}
                                    <Button size="small" type="primary" shape="circle" danger onClick={() => {
                                        Sweetalert.Confirm(BoxMsg.Title_Confirm, BoxMsg.Desc_Confirm_Logout, () => {
                                            localStorage.removeItem(process.env.REACT_APP_JWT_KEY as string);
                                            RedirectToLogin();
                                            Sweetalert.Close();
                                        });
                                    }}><i className="icofont icofont-logout"></i></Button>
                                    {/* <button
                    type="button"
                    className="btn btn-danger btn-logout"
                  >
                    <i className="fa fa-sign-out-alt"></i>
                  </button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content-body">
                        <div className={"body-side" + (NavCollapse ? " nav-collapse" : "")}>
                            <div className="main-nav">
                                <PerfectScrollbar
                                    className="nav-scroller"
                                    options={{ wheelSpeed: 0.25 }}
                                >
                                    {AdminMenu.length ? (
                                        <NavBar id="nav1" source={AdminMenu} isSide={true} />
                                    ) : (
                                        ""
                                    )}
                                </PerfectScrollbar>
                            </div>
                        </div>
                        <div className="body-main">
                            <div className="main-head">
                                <div className="head-flag">
                                    <div className="flag-item">{PageTitle}</div>
                                </div>
                            </div>
                            <div className="main-body">{props.children}</div>
                            {/* <div className="body-footer">
                {" "}
                Copyright © 2021 PTT PUBLIC COMPANY LIMITED | All Right
                Reserved.
              </div> */}
                        </div>
                    </div>
                    <div className="content-footer">
                        <div className="footer-panel">
                            <div className="footer-content">
                                <div className="footer-title">
                                    Copyright © 2021 PTT PUBLIC COMPANY LIMITED | All Right
                                    Reserved.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div id="CONTENT_BACK">
        <div id="CONTENT_BLOCK">
          <a id="MENU_CLOSE" onClick={toggleSideNav}>
            <i className="fas fa-exchange-alt"></i>
          </a>
        </div>
        <div className="content-header">
          <div className="header-item">
            <div className="main-title">
              <div className="logo-web">
                <a></a>
              </div>
              <div className="title">
                <div className="title-main">LNG</div>
                <div className="title-sub">Website</div>
              </div>
            </div>
            <div className="main-info">
              <div className="info-detail">
                <div className="info-title"> JIB </div>
              </div>
              <div className="info-tool">
                <i className="fa fa-sign-out-alt"></i>
              </div>
            </div>
            <a id="MENU_TRIGGER" role="button" onClick={toggleSideNav}>
              <i className="fa fa-bars"></i>
            </a>
          </div>
        </div>

        <div className="content-body">
          <div className="body-side">
            <div className="main-nav">
              <ul
                className="menu"
                id="navSIDE"
                style={{
                  transform:
                    isActiveST && isActiveRP
                      ? "translateX(0%)"
                      : "translateX(-100%)",
                }}
              >
                <li>
                  <a href="admin-userpermission">
                    <div className="menu-icon">
                      <i className="fa fa-users-cog"></i>
                    </div>
                    <div className="menu-label">สิทธิผู้ดูแลระบบ</div>
                  </a>
                  <a href="MasterType">
                    <div className="menu-icon">
                      <i className="fas fa-database"></i>
                    </div>
                    <div className="menu-label">ข้อมูลพื้นฐาน</div>
                  </a>
                </li>
                <li>
                  <a href="admin-news">
                    <div className="menu-icon">
                      <i className="far fa-newspaper"></i>
                    </div>
                    <div className="menu-label">จัดการข้อมูลข่าว</div>
                  </a>
                </li>
                <li>
                  <a href="admin_organization_chart_list">
                    <div className="menu-icon">
                      <i className="far fa-newspaper"></i>
                    </div>
                    <div className="menu-label">แผนภูมิองค์กร</div>
                  </a>
                </li>
                <li>
                  <a href="admin-Banner">
                    <div className="menu-icon">
                      <i className="far fa-newspaper"></i>
                    </div>
                    <div className="menu-label">Banner</div>
                  </a>
                </li>
                <li>
                  <a href="admin-Gallery">
                    <div className="menu-icon">
                      <i className="far fa-newspaper"></i>
                    </div>
                    <div className="menu-label">Gallery</div>
                  </a>
                </li>
                <li>
                  <a href="admin-procurement">
                    <div className="menu-icon">
                      <i className="far fa-newspaper"></i>
                    </div>
                    <div className="menu-label">
                      จัดการข้อมูลประกาศจัดซื้อจัดจ้าง
                    </div>
                  </a>
                </li>
              </ul>

            </div>
          </div>
          <div></div>
          <div className="body-main">
            <div className="main-head">
              <div className="head-flag">
                <div className="flag-item">
                  <div className="head-icon">
                    {props.titleIcon != "" ? props.titleIcon : false}
                  </div>
                  <div className="head-title">{setTitle()}</div>
                </div>
              </div>
            </div>

            <div className="main-body">{props.children}</div>
          </div>
        </div>

        <div className="content-footer">
          <div className="footer-panel">
            <div className="footer-content">
              <div className="footer-title">
                Powered by Softthai Application Co., Ltd.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="SITE_MOBILE">
        <div id="MENU_SIDE">
          <ul className="menu"></ul>
        </div>
      </div> */}
        </Fragment>
    );
};
export default MP_Back;
